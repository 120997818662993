<template>
  <audio :src="src" controls></audio>
</template>

<script>
import FileMixin from '@/mixins/FileViewMixin.js'

export default {
  name: 'ContentAudio',
  mixins: [FileMixin],
  props: {
    config: Object // raw firebase data
  },
  data () {
    return {
      src: null
    }
  },
  created () {
    let src = null
    if (this.$root.preloads) {
      const preload = this.$root.preloads.find(p => p.src === this.config.src)
      if (preload) {
        src = preload.url
      }
    }
    this.src = src || this.fileSrc(this.config.src)
  }
}
</script>

<style scoped>
audio {
  width: 100%;
}
</style>

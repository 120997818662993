<template>
  <v-expansion-panels class="expand">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>{{ config.title }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'ContentExpand',
  props: {
    config: Object
  }
}
</script>

<style scoped>
.expand {
  padding: 12px 0;
}
</style>

<template>
  <div width="100%" :style="'height: ' + (config.height || 200) + 'px; position: relative;'">
    <iframe :src="config.src" width="100%" height="100%" style="position: absolute;"></iframe>
  </div>
</template>

<script>
export default {
  name: 'ContentIframe',
  props: {
    config: Object
  }
}
</script>

<template>
  <div class="video">
    <iframe
      v-if="config.vimeoId"
      :src="'https://player.vimeo.com/video/' + config.vimeoId + '?color=82BE46&portrait=0&title=0&byline=0'"
      frameborder="0" allow="autoplay; fullscreen" allowfullscreen
    >
    </iframe>
    <iframe
      v-else-if="config.ytId"
      :src="'https://www.youtube.com/embed/' + config.ytId"
      frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'ContentVideo',
  props: {
    config: Object // raw firebase data
  }
}
</script>

<style scoped>
.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-img',{staticClass:"img-bg",attrs:{"src":_vm.imgSrc(_vm.config.src),"lazy-src":_vm.imgSrcLazy(_vm.config.src),"srcset":_vm.imgSrcSet(_vm.config.src),"aspect-ratio":_vm.config.ratio},on:{"click":function($event){_vm.zoom = 0}}}),(_vm.config.desc !== '')?_c('div',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.config.desc))]):_vm._e(),_c('photo-gallery',{attrs:{"images":[{
      src: this.imgSrc(this.config.src),
      thumSrc: this.imgSrcLazy(this.config.src),
      w: _vm.w,
      h: _vm.h,
      title: _vm.config.desc
    }]},model:{value:(_vm.zoom),callback:function ($$v) {_vm.zoom=$$v},expression:"zoom"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card shaped class="my-3">
    <v-card-title>
      <div>{{ $t('quiz') }}</div>
    </v-card-title>
    <v-card-text>
      {{ config.question }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ContentQuiz',
  props: {
    config: Object
  }
}
</script>

<template>
  <v-row
    justify="center"
    align="center"
    style="height: 50vh;"
  >
    <v-col class="text-center" >
      <router-link to="tours">{{ $t('tours') }}</router-link>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'home'
}
</script>

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// locales
import en from 'vuetify/es5/locale/en'
import de from 'vuetify/es5/locale/de'
import fr from 'vuetify/es5/locale/fr'
import it from 'vuetify/es5/locale/it'
import es from 'vuetify/es5/locale/es'
import ja from 'vuetify/es5/locale/ja'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#333',
        secondary: '#82BE46'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { en, de, fr, it, es, ja },
    current: 'en'
  }
})

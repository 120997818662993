import Vue from 'vue'
import App from './App.vue'
// import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import './plugins/googlecharts'
import './plugins/googlemaps'
import './plugins/reactiveprovide'
import { fb, db } from '@/firebase'

const HELP_LANGS = ['de', 'en']

Vue.config.productionTip = false

// TODO: remove when migrated to FireCrudLib!
Vue.prototype.$fb = {
  fb,
  db
}

// sync lang with vuetify
vuetify.framework.lang.current = i18n.locale

new Vue({
  // store,
  router,
  vuetify,
  i18n,
  data () {
    return {
      user: null,
      perms: null,
      subusers: null,
      accountId: null,
      userPerms: null,
      snack: '',
      upgradeDlg: false,
      upsellDlg: false,
      upsellType: null,
      backHandler: null,
      lastRoutePath: '',
      clipboard: null,
      helpContents: null
    }
  },
  computed: {
    userId () {
      return this.user ? this.user.uid : null
    },
    authorized () {
      if (this.user === false || this.accountId === false) return false
      if (this.user === null || this.userPerms === null || this.subusers === null || this.accountId === null || this.perms === null) return null
      return this.perms?.backend
    },
    subUser () {
      if (this.accountId === this.userId) return null
      return this.subusers?.find(s => s.accountId === this.accountId)
    },
    isSU () {
      return this.user?.root
    },
    appUrl () {
      const LIVE = location.hostname.indexOf('test') === -1
      return process.env['VUE_APP_APP_URL' + (LIVE ? '' : '_TEST')]
    }
  },
  methods: {
    initAuth () {
      fb.auth().onAuthStateChanged(async user => {
        if (user) {
          // user auth & claims
          const idTokenResult = await user.getIdTokenResult()
          const userAuth = { uid: user.uid, email: user.email, ...(idTokenResult.claims || {}) }
          
          // user data
          const userDoc = await db.doc('users/' + user.uid).get()
          const userData = userDoc.exists ? userDoc.data() : {}
          user = Object.assign({}, userAuth, userData)
          this.user = user
  
          // user perms
          const userPermsDoc = await db.doc('perms/' + user.uid).get()
          const userPerms = userPermsDoc.exists ? userPermsDoc.data() : {}
          this.userPerms = userPerms
  
          // sub users (listener for subuser signup process)
          const subusers = []
          this.unsubscribe = db.collection('subusers')
            .where('userId', '==', user.uid)
            .onSnapshot(async subusersSnap => {
              subusersSnap.forEach(doc => {
                const subuser = doc.data()
                if (!subuser._deleted) {
                  subusers.push(subuser)
                }
              })
              this.subusers = subusers
      
              // determine account
              let accountId = localStorage.getItem('accountId')
              // if not selected or no longer available, fall back
              if (!accountId || (accountId !== user.uid && !subusers.find(s => s.accountId === accountId))) {
                // if backend user, select this one, otherwise try first subuser (if any)
                if (userPerms.backend) {
                  accountId = user.uid
                } else if (subusers.length > 0) {
                  accountId = subusers[0].accountId
                } else {
                  accountId = false
                }
              }
              this.accountId = accountId
    
              if (accountId) {
                // determine perms
                let perms = null
                if (accountId === user.uid) {
                  // user perms
                  perms = userPerms
                } else {
                  // account perms
                  const accountPermsDoc = await db.doc('perms/' + accountId).get()
                  perms = accountPermsDoc.exists ? accountPermsDoc.data() : {}
                }
                this.perms = perms
              }
            })


          // const subusersSnap = await db.collection('subusers')
          //   .where('userId', '==', user.uid)
          //   .get()
          // subusersSnap.forEach(doc => {
          //   const subuser = doc.data()
          //   if (!subuser._deleted) {
          //     subusers.push(subuser)
          //   }
          // })
          // this.subusers = subusers
  
          // // determine account
          // let accountId = localStorage.getItem('accountId')
          // // if not selected or no longer available, fall back
          // if (!accountId || (accountId !== user.uid && !subusers.find(s => s.accountId === accountId))) {
          //   // if backend user, select this one, otherwise try first subuser (if any)
          //   if (userPerms.backend) {
          //     accountId = user.uid
          //   } else if (subusers.length > 0) {
          //     accountId = subusers[0].accountId
          //   } else {
          //     accountId = false
          //   }
          // }
          // this.accountId = accountId

          // if (accountId) {
          //   // determine perms
          //   let perms = null
          //   if (accountId === user.uid) {
          //     // user perms
          //     perms = userPerms
          //   } else {
          //     // account perms
          //     const accountPermsDoc = await db.doc('perms/' + accountId).get()
          //     perms = accountPermsDoc.exists ? accountPermsDoc.data() : {}
          //   }
          //   this.perms = perms
          // }
        } else {
          // user is signed out
          if (this.unsubscribe) {
            this.unsubscribe()
          }
          this.user = false
          this.accountId = null
          this.perms = null
          this.userPerms = null
          this.subusers = null
        }
      })
    },
    setLocale (locale) {
      this.$i18n.setLocale(locale)
      this.$vuetify.lang.current = locale
      this.loadHelp()
    },
    goBack () {
      if (this.backHandler) {
        this.backHandler(this.navigateBack)
      } else {
        this.navigateBack()
      }
    },
    navigateBack () {
      this.lastRoutePath = this.$route.path
      this.$router.back()
    },
    async loadHelp (helpId) {
      if (helpId) {
        this.lastHelpId = helpId
      } else {
        helpId = this.lastHelpId
      }
      if (helpId) {
        const lang = HELP_LANGS.includes(this.$vuetify.lang.current) ? this.$vuetify.lang.current : 'en'
        const helpDoc = await db.doc('public-settings/' + helpId + '__' + lang).get()
        if (helpDoc.exists) {
          this.helpContents = helpDoc.data().contents
        } else {
          this.helpContents = null
        }
      }
    },
    selectAccount (accountId) {
      localStorage.setItem('accountId', accountId)
      window.location.href = window.location.origin + '?accountId=' + accountId // to be sure url changes
    },
    signOut () {
      fb.auth().signOut()
    }
  },
  watch: {
    async $route ($route) {
      if ($route.query.tkn) {
        await fb.auth().signOut()
        await fb.auth().setPersistence(fb.auth.Auth.Persistence.SESSION)
        fb.auth().signInWithCustomToken($route.query.tkn)
          .then(userCredential => {
            console.log('tkn auth success', userCredential.user.email)
            // this will retrigger this handler but with tkn = false
            const queryUpdate = { ...$route.query }
            delete queryUpdate.tkn
            this.$router.replace({ queryUpdate })
          })
          .catch(err => {
            console.error('tkn auth error', err)
          })
      } else {
        this.initAuth()
      }
    }
  },
  render: h => h(App)
}).$mount('#app')

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/firebase-storage'
import 'firebase/functions'

const USE_EMULATOR_SUITE = location.hostname === 'localhost'
const LIVE = location.hostname.indexOf('test') === -1
const FUNCTIONS_REGION = LIVE ? 'europe-west1' : 'europe-west6'

const firebaseConfig = LIVE ? {
  apiKey: 'AIzaSyCilM7Urtd1wSzw21S33nLVKBkAfbqtDZ8',
  authDomain: 'noble-descent-263011.firebaseapp.com',
  databaseURL: 'https://noble-descent-263011.firebaseio.com',
  projectId: 'noble-descent-263011',
  storageBucket: 'noble-descent-263011.appspot.com',
  messagingSenderId: '642414255078',
  appId: '1:642414255078:web:24e268517153f12213d427'
} : {
  apiKey: "AIzaSyDv3Qayshki5APzwLsBOYVSZ_JF721Ge9k",
  authDomain: "smartrails-test.firebaseapp.com",
  projectId: "smartrails-test",
  storageBucket: "smartrails-test.appspot.com",
  messagingSenderId: "215857158662",
  appId: "1:215857158662:web:a896d9f809744e376e4187"
}

const fb = firebase

const app = firebase.initializeApp(firebaseConfig)

const db = app.firestore()
if (USE_EMULATOR_SUITE) {
  db.useEmulator('localhost', 5002)
}

const fs = firebase.storage()

const fn = app.functions(FUNCTIONS_REGION)
if (USE_EMULATOR_SUITE) {
  fn.useEmulator('localhost', 5001)
}


export { fb, app, db, fs, fn }

<template>
  <div v-if="contents">
    <component
      v-for="(item, i) in contents" :key="i"
      :is="getCmp(item.type)"
      :config="item"
    >
      <component
        v-for="(sub, s) in item.contents" :key="'s' + s"
        :is="getCmp(sub.type)"
        :config="sub">
      </component>
    </component>
  </div>
</template>

<script>
import ContentAudio from './ContentAudio.vue'
import ContentCarousel from './ContentCarousel.vue'
import ContentExpand from './ContentExpand.vue'
import ContentHtml from './ContentHtml.vue'
import ContentIframe from './ContentIframe.vue'
import ContentImage from './ContentImage.vue'
import ContentQuiz from './ContentQuiz.vue'
import ContentText from './ContentText.vue'
import ContentVideo from './ContentVideo.vue'

export default {
  name: 'ContentRoot',
  components: {
    ContentAudio,
    ContentCarousel,
    ContentExpand,
    ContentHtml,
    ContentIframe,
    ContentImage,
    ContentQuiz,
    ContentText,
    ContentVideo
  },
  props: {
    contents: Array
  },
  methods: {
    getCmp (type) {
      let typeUpper = type.charAt(0).toUpperCase() + type.slice(1)
      return 'Content' + typeUpper
    }
  }
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/tours'
      // beforeEnter: (to, from, next) => {
      //   // for tkn auth, continue (stay on empty home page to avoid snap listener errs), but otherwise fwd to /tours
      //   if (to.query?.tkn) {
      //     next()
      //   } else {
      //     next('/tours')
      //   }
      // }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
    },
    {
      path: '/tours',
      name: 'tours',
      component: () => import(/* webpackChunkName: "tours" */ './views/Tours.vue')
    },
    {
      path: '/tours/:tourId',
      name: 'tour',
      component: () => import(/* webpackChunkName: "tour" */ './views/Tour.vue')
    },
    {
      path: '/tours/:tourId/posts/:postId',
      name: 'post',
      component: () => import(/* webpackChunkName: "post" */ './views/Post.vue')
    },
    {
      path: '/tours/:tourId/pois/:poiId',
      name: 'poi',
      component: () => import(/* webpackChunkName: "poi" */ './views/Poi.vue')
    },
    {
      path: '/subusers/:id?',
      name: 'subusers',
      component: () => import(/* webpackChunkName: "subusers" */ './views/Subusers.vue')
    },
    {
      path: '/store/:id?',
      name: 'store',
      component: () => import(/* webpackChunkName: "store" */ './views/Store.vue')
    },
    {
      path: '/live/:id?',
      name: 'Live',
      component: () => import(/* webpackChunkName: "live" */ './views/Live.vue')
    }
  ]
})

<template>
  <div v-html="config.text" class="text"></div>
</template>

<script>
export default {
  name: 'ContentText',
  props: {
    config: Object
  }
}
</script>

<style scoped>
.text >>> p {
  margin-bottom: 0px;
}
</style>

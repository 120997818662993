<template>
  <div>
    <v-carousel
      :cycle="config.cycle"
      :continuous="config.continuous"
      :interval="config.interval"
      :hide-delimiters="true"
      height="auto">
      <v-carousel-item
        v-for="(item, i) in config.contents"
        :key="i"
        :src="imgSrc(item.src)"
        :lazy-src="imgSrcLazy(item.src)"
        :srcset="imgSrcSet(item.src)"
        :aspect-ratio="item.ratio"
        eager
        @click="zoom = i"
      >
        <div v-if="item.desc" class="img-legend">{{ item.desc }}</div>
      </v-carousel-item>
    </v-carousel>

    <photo-gallery
      v-model="zoom"
      :images="zoomImages"
    />
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileViewMixin.js'
import { PhotoGallery } from 'vue-photo-gallery'

export default {
  name: 'ContentCarousel',
  mixins: [FileMixin],
  components: {
    PhotoGallery
  },
  props: {
    config: Object
  },
  data () {
    return {
      zoom: null
    }
  },
  computed: {
    zoomImages () {
      return this.config.contents.map(item => {
        const w = window.innerWidth * 2
        const h = Math.floor(w / item.ratio)
        return {
          src: this.imgSrc(item.src),
          thumSrc: this.imgSrcLazy(item.src),
          w: w,
          h: h,
          title: item.desc
        }
      })
    }
  }
}
</script>

<style scoped>
.img-legend {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2px 4px;
  z-index: 1;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  font-weight: 300;
  line-height: 1.2;
}
</style>

<template>
  <div>
    <v-img
      :src="imgSrc(config.src)"
      :lazy-src="imgSrcLazy(config.src)"
      :srcset="imgSrcSet(config.src)"
      :aspect-ratio="config.ratio"
      class="img-bg"
      @click="zoom = 0"
    />

    <div v-if="config.desc !== ''" class="font-italic">{{ config.desc }}</div>

    <photo-gallery
      v-model="zoom"
      :images="[{
        src: this.imgSrc(this.config.src),
        thumSrc: this.imgSrcLazy(this.config.src),
        w: w,
        h: h,
        title: config.desc
      }]"
    />
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileViewMixin.js'
import { PhotoGallery } from 'vue-photo-gallery'

export default {
  name: 'ContentImage',
  mixins: [FileMixin],
  components: {
    PhotoGallery
  },
  props: {
    config: Object
  },
  data () {
    return {
      zoom: null
    }
  },
  computed: {
    w () {
      return window.innerWidth * 2
    },
    h () {
      return Math.floor(this.w / this.config.ratio)
    }
  }
}
</script>

<template>
  <div class="mt-10">
    <div id="firebaseui-auth-container"></div>
  </div>
</template>

<script>
import { fb } from '@/firebase'
import * as firebaseui from 'firebaseui'
import '../../node_modules/firebaseui/dist/firebaseui.css'

export default {
  name: 'LoginForm',
  mounted () {
    let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(fb.auth())
    ui.start('#firebaseui-auth-container', {
      callbacks: {
        signInSuccessWithAuthResult (authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
        },
        uiShown () {
          // The widget is rendered.
          // Hide the loader.
        }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      // signInFlow: 'popup',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        // fb.auth.GoogleAuthProvider.PROVIDER_ID,
        // fb.auth.FacebookAuthProvider.PROVIDER_ID,
        // fb.auth.TwitterAuthProvider.PROVIDER_ID,
        // fb.auth.GithubAuthProvider.PROVIDER_ID,
        fb.auth.EmailAuthProvider.PROVIDER_ID
        // fb.auth.PhoneAuthProvider.PROVIDER_ID
      ]
    })
  }
}
</script>

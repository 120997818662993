<template>
  <div v-html="config.html"></div>
</template>

<script>
export default {
  name: 'ContentHtml',
  props: {
    config: Object
  }
}
</script>

export default {
  en: {
    anonymous: 'Anonymous',
    loading: 'loading...',
    profile: 'Profile',
    unauthorized: 'UNAUTHORIZED',
    code: 'Code',
    contact_support: 'Contact support',
    signed_in_as: 'Angemeldet als',
    sign_out: 'Abmelden',
    qr_hint: 'ATTENTION: The URL in the QR Code contains the name of the trail. If the trail is renamed, you must generate a new QR Code. Posts and POIs can however be renamed at any time without the QR Code becoming invalid.',
    qr_hint_post: 'This QR code leads directly to the post when scanned.',
    correct: 'Correct',
    month: 'Month',
    quizzes: 'Quizzes',
    quizzes_correct: 'Quizzes correct',
    rating: 'Rating',
    rating_remarks: 'Feedbacks',
    tour_not_public: 'This trail is not published yet.',
    visitors: 'Visitors',
    week: 'Week',
    wrong: 'Wrong',
    year: 'Year',
    total: 'Total',
    file_missing: 'File missing!',
    images: 'Images',
    cycle: 'Auto start',
    continuous: 'Continuous (repeat)',
    interval: 'Interval (sec)',
    iframe_height: 'Height (optional, also specify dimension (px or %))',
    iframe_height_custom: 'Set custom height',
    icon: 'Icon',
    icon_auge: 'Eye',
    icon_aussichtspunkt: 'Viewpoint',
    icon_bahnhof: 'Station',
    icon_bushaltestelle: 'Bus stop',
    icon_einkaufsmoeglichkeit: 'Shopping facility',
    icon_feuerstelle: 'Fireplace',
    icon_haus: 'House',
    icon_kreuz: 'Cross (hospital, doctor)',
    icon_parkplatz: 'Parking lot',
    icon_rastplatz: 'Rest area',
    icon_restaurant: 'Restaurant',
    icon_sehenswuerdigkeit: 'Attraction',
    icon_seilbahn: 'Cable car',
    icon_spielplatz: 'Playground',
    icon_stern: 'Star',
    icon_touristinfo: 'Touristinfo',
    icon_wc: 'WC',
    account: 'Account',
    account_required: 'Account required',
    add_answer: 'Add answer',
    answer: 'Answer',
    answer_correct: 'Correct Answer',
    answers: 'Answers',
    answer_text_hint: 'Add multiple solutions separated by semicolon',
    before: 'before',
    choice: 'Choice',
    created: 'Created',
    date_format: 'DD.MM.YYYY',
    expiry_date: 'Valid until',
    expiry_date_opt: 'Valid until (optional)',
    explain_correct: 'Explanation after correct answer',
    explain_wrong: 'Explanation after wrong answer',
    explain_won: 'Explanation after game won',
    explain_lost: 'Explanation after game lost',
    explainer_img: 'Explainer Image (optional)',
    explainer_text: 'Explainer',
    resolve: 'Show correct answer after submission',
    number: 'Number',
    mode: 'Mode',
    question: 'Question',
    text: 'Text',
    tries: 'Tries',
    glb_missing: 'GLB File missing!',
    usdz_missing: 'USDZ File missing!',
    howto_text: 'Howto-Text',
    ar_howto: 'If you do not specify any text, the following description will be displayed: Tap on the round button and place the object on a free area by moving your device back and forth. You can then walk around the object, look at it from all sides, move it (tap) or change its size (zoom gesture).',
    glb_file: 'GLB File',
    glb_file_hint: 'required',
    usdz_file: 'USDZ File',
    usdz_file_hint: 'optional for animated objects',
    hr: 'Line',
    or: 'or',
    vimeoid: 'Vimeo Video Id',
    ytvidid: 'YouTube Video Id',
    nocontent: 'Add sub content per drag-and-drop.',
    nocontent_copy: 'Copy content from main language',
    nocontent_info: 'No content yet - the content from the main language will be used.',
    nocontent_sub: 'Add sub content with the green plus button and drag it into this element',
    carousel: 'Carousel',
    audio: 'Audio',
    video: 'Video',
    expand: 'Expand',
    space: 'Space',
    height: 'Height',
    timer: 'Timer',
    timer_time: 'Time (Minutes)',
    timer_mode: 'Mode',
    timer_mode_countup: 'Timer (ascending)',
    timer_mode_countdown: 'Countdown (descending)',
    html: 'Html',
    smartvue: 'smartVue (AR)',
    iframe: 'Iframe',
    additionallangs: 'Additional languages',
    delete_ask: 'Delete all contents and sub-contents?',
    mainlang: 'Main language',
    image_choose: 'Choose image',
    or_dnd: 'or drag and drop image here',
    remove: 'Remove',
    never: 'never',
    no_data: 'No Data..',
    delete_poi: 'Delete POI',
    form: 'Form',
    forms: 'Forms',
    form_email_hint: 'If empty, the correspondence E-Mail (in settings) will be used.',
    game: 'Game',
    game_imagepairs: 'Image Pairs',
    game_imagepairs_img_hint: 'The images must have a square format.',
    game_falldown: 'Image Rain',
    game_hangman: 'Guess word',
    game_won: 'Won',
    game_lost: 'Lost',
    game_score: 'Number of "Corrects" to win (Smileys)',
    game_lives: 'Number of "Lives" per try (Hearts)',
    game_idea: 'Submit your idea for another game',
    game_instructions: 'Game Instructions',
    game_instructions_show: 'Show Game Instructions',
    game_instructions_imagepairs: 'Which pictures belong together? Tap on one of the pictures listed below and then on the corresponding field.',
    game_instructions_falldown: 'If the image that falls down corresponds to [CRITERIA REPLACE HERE], then press the credit sign - otherwise press the cross. For each hit you get a smiley, each mistake costs a heart.',
    game_instructions_hangman: 'Tap on the letters that you think appear in the word you are looking for. For every hit you get a smiley, every miss costs a heart.',
    game_resolve: 'Show the correct solution in case of Game Over',
    // game_instructions_hint: 'Optional, will be shown before the game starts.',
    games: 'Games',
    games_won: 'Games won',
    generate: 'Generate',
    poi_disable: 'Disable POI',
    poi_disabled: 'POI disabled',
    poi_enable: 'Enable POI',
    poi_enabled: 'POI enabled',
    poi_invisible: 'POI invisible',
    poi_open_on_visit: 'Automatically open POI on spot',
    delete_post: 'Delete Post',
    post_disable: 'Disable Post',
    post_disabled: 'Post disabled',
    post_enable: 'Enable Post',
    post_enabled: 'Post enabled',
    post_invisible: 'Post invisible',
    qr_lock: 'Only show content after QR Code Scan',
    qr_lock_text: 'QR Code Scan Hint',
    qr_lock_text_default: 'Default: Search and scan the QR Code to unlock this content',
    quantity: 'Quantity',
    placeholder: 'Placeholder Text',
    profile_name: 'Profile Name',
    profile_map: 'Map with Trails',
    profile_map_openstreetmap: 'OpenStreetMap Roadmap',
    profile_map_swisstopo_pixelkarte_farbe: 'SwissTopo Roadmap',
    profile_map_swisstopo_swissimage: 'SwissTopo Satellite',
    profile_public: 'Make Profile available to the public',
    profile_public_hint: 'If checked, your profile can be found on the smarTrails website',
    profile_url: 'External URL for Profile Page',
    perms: 'Permissions',
    perm_tour_add: 'Create Trails',
    perm_tour_delete: 'Delete Trails',
    perm_tour_publish: 'Publish Trails',
    tour_perms: 'Access to Trails',
    confirm_title_change: 'If you rename the Trail, the URL (Web Address) will change too.<br><br>Make sure that you adapt external links and replace any existing QR-Codes.',
    default_layer_posts: 'Start on posts overview',
    delete_tour: 'Delete Trail',
    finish: 'Finish',
    finishpage: 'Finish Page',
    finish_open_after_num_posts: 'Open Finish Page after visiting number of posts',
    map_type: 'Map Type',
    map_type_openstreetmap: 'OpenStreetMap Roadmap',
    map_type_google_roadmap: 'Google Maps Roadmap',
    map_type_google_satellite: 'Google Maps Satellite',
    map_type_swisstopo_pixelkarte_farbe: 'SwissTopo Roadmap',
    map_type_swisstopo_swissimage: 'SwissTopo Satellite',
    payments: 'Payments',
    // payment_info: 'Contact us to get help setting up your Payrexx-Account',
    pay_instance: 'Payrexx Instance',
    pay_secret: 'Payrexx API Secret',
    pay_amount: 'Amount (CHF)',
    payrexx: 'Payrexx',
    payrexx_open: 'Open Payrexx Account', // TODO
    pois_before_posts: 'Show POIs before Posts',
    register: 'Registrate',
    register_finishing: 'Initializing Account, please wait..', // TODO
    posts_open_unvisited: 'Allow opening unvisited posts',
    posts_order_show: 'Show posts order',
    posts_order_force: 'Force posts order',
    posts_show_next_only: 'Show only next post',
    posts_show_next_only_hint: 'Show only visited and the next post. Visitors discover the trail step by step.',
    properties: 'Properties',
    publish_on: 'Link on',
    pub_pub: 'Published',
    pub_chg: 'Unpublished changes',
    pub_not: 'Not published',
    send_email: 'Send E-Mail',
    send_email_stats: 'Include statistics data',
    signup: 'Sign Up',
    support: 'Support',
    tour_discover: 'discover.swiss',
    tour_embeddable: 'External websites (embedded)',
    tour_duration: 'Duration (minutes)',
    tour_length: 'Length (meters)',
    tour_open: 'Open',
    tour_public: 'Profile page',
    tour_public_hint: 'If you check this box, the trail can be found and started on the smarTrails website. This after a review by Tourify GmbH.',
    tour_prevent_restart: 'Prevent restarting the trail',
    tour_tags: 'Tags',
    tour_title: 'Trail Name',
    type: 'Type',
    about: 'About',
    add: 'Add',
    agb: 'AGB',
    attributes: 'Attributes',
    buy: 'Buy',
    cancel: 'Cancel',
    collab: 'Collaboration',
    confirm: 'Are you sure?',
    confirm_del_content_and_sub: 'Delete content and sub-contents?',
    content: 'Content',
    copy: 'Copy',
    copy_noun: 'Copy',
    copied: 'Copied',
    coords_east: 'Coordinates East',
    coords_north: 'Coordinates North',
    coords_south: 'Coordinates South',
    coords_west: 'Coordinates West',
    correspondence: 'Correspondence',
    delete: 'Delete',
    delete_yes: 'Yes, delete!',
    deleted: 'Deleted',
    description: 'Description',
    disabled: 'Disabled',
    discard: 'Discard',
    document: 'Document',
    edit: 'Edit',
    email: 'E-Mail',
    embed: 'Embed',
    embed_hint_singular: 'Add this code to your website to embed the trail',
    embed_hint_plural: 'Add this code to your website to embed your trails',
    err_buy_no_recipient: 'Recipient is missing!',
    err_no_valid_posts: 'No posts, content or positions found',
    err_poi_invalid_pos: 'POI without position found',
    err_post_invalid_pos: 'Post without position found',
    err_slug_not_unique: 'Trail with the same name found',
    feedback: 'Feedback',
    feedback_show: 'Show feedback button',
    field_required: 'This fiels is required',
    file: 'File',
    file_too_big: 'File too big! Limit: {limit}MB',
    finished: 'Finished',
    general: 'General',
    help: 'Help',
    image: 'Image',
    image_header: 'Header Image (wide! ca. 8/1)',
    image_tile: 'Image (ca. 16/9, for Tiles)',
    import: 'Importieren',
    imprint: 'Imprint & Privacy Policy',
    lang: 'Language',
    langs: 'Languages',
    live: 'Live',
    logout: 'Logout',
    name: 'Name',
    new: 'New',
    no: 'No',
    map_overlay: 'Individual Card',
    map_overlay_img: 'Image (map section)',
    map_overlay_north_west: 'North-West (Top-Left) Corner', // TODO
    map_overlay_south_east: 'South-East (Bottom-Right) Corner', // TODO
    map_route: 'Route',
    map_route_calculate: 'Calculate Route',
    map_route_import: 'Import Route (GPX-File)',
    max_value: 'Maximum',
    min_value: 'Minimum',
    open_in_app: 'Preview',
    optional: 'optional',
    overview: 'Overview',
    poi: 'POI',
    pois: 'POI',
    position: 'Position',
    position_missing: 'Position missing!',
    post: 'Post',
    posts: 'Posts',
    preview: 'Preview',
    price: 'Price',
    pricing: 'Packages & Pricing',
    provider: 'Provider',
    public: 'Public',
    publish: 'Publish',
    published: 'Published',
    qrcode: 'QR Code',
    quiz: 'Quiz',
    radius: 'Radius',
    radius_hint: 'Recommended: 15m; Use more in narrow areas',
    redeemed: 'Redeemed',
    redeemed_by: 'Redeemed by',
    redeemed_at: 'Redeemed at',
    save: 'Save',
    save_changes: 'Save changes?',
    save_publish: 'Save and publish',
    selfservice: 'Self-service function for unlocking posts',
    services: 'Additional Services',
    settings: 'Settings',
    share_show: 'Show Share-Button',
    slug: 'Slug',
    start: 'Start',
    started: 'Started',
    state: 'Status',
    state_closed: 'closed',
    state_open: 'open',
    stats: 'Statistics',
    store: 'Store',
    store_buy_tour: 'Purchase Trail',
    store_buy_tour_info: 'Please provide the following information to purchase the trail:',
    store_buy_tour_recipient: 'Billing address',
    store_from: 'From Store',
    store_publish: 'Publish in Store',
    store_publish_tos_hint: 'I confirm that I offer the trail in the smarTrails Store for further use and possibly further processing by third parties. I also confirm that the trail does not contain any content (text, images, audio recordings, videos, etc.) that is protected by by third-party copyrights.',
    store_amount: 'Amount (CHF)',
    store_amount_free: '0.- (for free)',
    store_amount_vat: 'VAT',
    store_amount_vat_excl: 'plus VAT',
    store_amount_vat_incl: 'VAT included',
    store_amount_vat_none: 'no VAT',
    store_tour_imported_info: 'Trail successfully imported.\nFor Outdoor Trails all Posts and POIs must be repositioned.',
    store_tour_edit: 'Edit Trail',
    store_resell: 'Allow Reselling',
    subusers: 'Sub Users',
    // support: 'Support',
    // support_infos: 'Support Informations',
    // support_infos_title: 'Your contact details',
    support_contact: 'Contact for support requests',
    tel: 'Phone',
    title: 'Title',
    title_quiz_hint: 'Optional. Examples: "Quiz", "Quest", ..',
    tour: 'Trail',
    tours: 'Trails',
    upgrade: 'Upgrade',
    upgrade_btn: 'Get in touch',
    upgrade_link: 'https://tourify.ch/kontakt',
    upgrade_text: 'Limit reached - please contact us to talk about an upgrade.',
    upgrade_info: '<a href="https://tourify.ch/smartrails/pakete" target="_blank">Packages & Pricing</a>',
    upgrade_offer_btn: 'Request an offer',
    upgrade_offer_link: 'https://tourify.ch/offerte-anfordern/',
    upsell_text: 'This feature is not included in your current license. Please contact us to talk about an upgrade.',
    upsell_maps_title: 'Advanced map functions',
    upsell_maps_text: 'With the «Maps Module» you can make your trails even more individual by including alternative maps in the form of graphic files. In addition, it allows you to draw in a route - automatically, manually or by uploading a GPX file.',
    upsell_payrexx_text: 'Offer your trails for a fee. Thanks to the interface to the Swiss payment provider Payrexx, various payment methods can be easily and conveniently integrated - for example, credit cards, Apple Pay or TWINT.',
    upsell_timer_text: 'The «Games module» allows you to set a time limit for the trail. Various online games are also available, which can be configured and added to the posts with just a few clicks.',
    upsell_vouchers_text: 'Generate voucher codes to start the trail and sell them directly in your online store or tourist office.',
    upsell_whitelabel_text: 'Design the appearance of the trail according to your corporate design. With «White Label» you can customize the colors and fonts according to your ideas, configure the menu items individually or replace the smarTrails logo with your own.\n\nPrice for all your trails: 1200.- CHF/year\n<small>Requirement: Advanced Edition or higher</small>',
    user: 'User',
    visited_posts: 'Visited Posts',
    voucher: 'Voucher',
    voucher_add: 'Add Voucher',
    voucher_code_exists: 'Code exists already!',
    voucher_code_rule: 'Code must be alphanumeric & uppercase & 8 chars long.',
    voucher_note: 'Purpose (internal)',
    vouchers: 'Vouchers',
    vouchers_generate: 'Generate Vouchers',
    vouchers_show: 'Show Vouchers',
    whitelabel: 'White Label',
    word: 'Word',
    word_rule: 'Only letters (without umlauts) allowed',
    yes: 'Yes',

    en: 'English',
    de: 'German',
    fr: 'French',
    it: 'Italian',
    rm: 'Romansh',
    es: 'Spanish',
    ja: 'Japanese',

    'tag_trail-loopTour': 'loop',
    'tag_trail-scenic': 'scenic',
    'tag_general-suitableforfamilies': 'family friendly',
    'tag_general-dogFriendly': 'dog friendly',
    'tag_accessibility-suitableforperambulators': 'suitable for strollers',
    'tag_transport-publicTransportFriendly': 'accessible by public transport',
    'tag_general-educational': 'educational',
    'tag_incentive-quiz': 'with puzzles',
    'tag_incentive-reward': 'with reward at the end',
    'tag_general-playground': 'playground along the way',
    'tag_general-fireplace': 'fire pit along the way',
    'tag_general-dining': 'refreshment stops available',
    'tag_general-shopping': 'shopping options available',
    'tag_general-publictoilet': 'public toilet available',
    'tag_accessibility-wheelchair-accessible': 'disability access'
  },
  de: {
    anonymous: 'Anonym',
    loading: 'wird geladen...',
    profile: 'Profilseite',
    unauthorized: 'KEIN ZUTRITT',
    contact_support: 'Support kontaktieren',
    signed_in_as: 'Angemeldet als',
    sign_out: 'Abmelden',
    qr_hint: 'ACHTUNG: Die URL im QR Code enthält den Namen des Trails. Falls dieser später umbenannt wird, müssen Sie einen neuen QR Code generieren. Posten und POIs können hingegen jederzeit umbenannt werden, ohne dass der QR Code ungültig wird.',
    qr_hint_post: 'Dieser QR Code führt direkt auf den Posten, wenn er gescannt wird.',
    correct: 'Richtig',
    month: 'Monat',
    quizzes: 'Quizze',
    quizzes_correct: 'Quizze richtig',
    rating: 'Bewertung',
    rating_remarks: 'Feedbacks',
    tour_not_public: 'Dieser Trail wurde noch nicht publiziert.',
    visitors: 'Besucher',
    week: 'Woche',
    wrong: 'Falsch',
    year: 'Jahr',
    total: 'Total',
    file_missing: 'Datei fehlt!',
    images: 'Bilder',
    cycle: 'Automatisch abspielen',
    continuous: 'Endlos Schleife',
    interval: 'Intervall (sek)',
    iframe_height: 'Fixe Höhe (z.Bsp. 500px) oder Prozent vom Bildschirm (z. Bsp. 80%)',
    iframe_height_custom: 'Höhe manuell setzen',
    icon: 'Icon',
    icon_auge: 'Auge',
    icon_aussichtspunkt: 'Aussichtspunkt',
    icon_bahnhof: 'Bahnhof',
    icon_bushaltestelle: 'Bushaltestelle',
    icon_einkaufsmoeglichkeit: 'Einkaufsmöglichkeit',
    icon_feuerstelle: 'Feuerstelle',
    icon_haus: 'Haus',
    icon_kreuz: 'Kreuz (Spital, Arzt)',
    icon_parkplatz: 'Parkplatz',
    icon_rastplatz: 'Rastplatz',
    icon_restaurant: 'Restaurant',
    icon_sehenswuerdigkeit: 'Sehenswürdigkeit',
    icon_seilbahn: 'Seilbahn',
    icon_spielplatz: 'Spielplatz',
    icon_stern: 'Stern',
    icon_touristinfo: 'Touristinfo',
    icon_wc: 'WC',
    account: 'Account',
    account_required: 'Account erforderlich',
    add_answer: 'Antwort hinhzufügen',
    answer: 'Antwort',
    answer_correct: 'Richtige Antwort',
    answers: 'Antworten',
    answer_text_hint: 'Mehrere Antworten semikolon-getrennt angeben',
    before: 'vor dem',
    choice: 'Auswahl',
    created: 'Erstellt',
    date_format: 'TT.MM.JJJJ',
    expiry_date: 'Gültig bis',
    expiry_date_opt: 'Gültig bis (optional)',
    explain_correct: 'Erklärung nach richtiger Beantwortung', // TODO
    explain_wrong: 'Erklärung nach falscher Beantwortung', // TODO
    explain_won: 'Erklärung nach gewonnenem Game',
    explain_lost: 'Erklärung nach verlorenem Game',
    explainer_img: 'Bild zur Erklärung (optional)',
    explainer_text: 'Erklärung',
    resolve: 'Nach Beantwortung die korrekte Lösung anzeigen',
    number: 'Nummer',
    mode: 'Modus',
    question: 'Frage',
    text: 'Text',
    tries: 'Anzahl Versuche',
    glb_missing: 'GLB Datei fehlt!',
    usdz_missing: 'USDZ Datei fehlt!',
    howto_text: 'Anleitungs-Text',
    ar_howto: 'Falls Sie keinen Text angeben, wird die folgende Beschreibung angezeigt: Tippen Sie auf den runden Button und platzieren Sie das Objekt auf einer freien Fläche, indem Sie Ihr Gerät hin- und herbewegen. Anschliessend können Sie um das Objekt herumlaufen, es von allen Seiten betrachten, es verschieben (Antippen) oder seine Grösse ändern (Zoom-Geste).',
    glb_file: 'GLB Datei',
    glb_file_hint: 'erforderlich',
    usdz_file: 'USDZ Datei',
    usdz_file_hint: 'optional für animierte Objekte',
    hr: 'Linie',
    or: 'oder',
    nocontent: 'Kein Inhalt',
    nocontent_copy: 'Inhalt aus Hauptsprache kopieren',
    nocontent_info: 'Noch kein Inhalt - es wird der Inhalt der Hauptsprache verwendet.',
    nocontent_sub: 'Fügen Sie Unterelemente über den grünen Button hinzu und ziehen Sie diese anschliessend per Drag-and-Drop hier hinein.',
    additionallangs: 'Zusatzsprachen',
    delete_ask: 'Alle Inhalte und Unterinhalte werden gelöscht! Sind Sie sicher?',
    mainlang: 'Hauptsprache',
    image_choose: 'Bild auswählen',
    or_dnd: 'oder Bild per Drag and Drop hier hin ziehen',
    remove: 'Entfernen',
    never: 'nie',
    no_data: 'Keine Daten..',
    delete_poi: 'POI löschen',
    form: 'Formular',
    forms: 'Formulare',
    form_email_hint: 'Falls leer, wird E-Mail an Korrespondenz-Adresse (unter Einstellungen) versendet.',
    game: 'Game',
    game_imagepairs: 'Bilder-Paare',
    game_imagepairs_img_hint: 'Die Bilder müssen ein quadratisches Format aufweisen.',
    game_falldown: 'Bilder-Regen',
    game_hangman: 'Wort erraten',
    game_won: 'Gewonnen',
    game_lost: 'Verloren',
    game_score: 'Anzahl "Richtige" zum Sieg (Smileys)',
    game_lives: 'Anzahl "Leben" pro Versuch (Herzchen)',
    game_idea: 'Idee für weiteres Game einreichen',
    game_instructions: 'Spielanleitung',
    game_instructions_show: 'Spielanleitung anzeigen',
    game_instructions_imagepairs: 'Welche Bilder gehören zusammen? Tippen Sie auf eines der unten aufgereihten Bilder und anschliessend auf das entsprechende Feld.',
    game_instructions_falldown: 'Entspricht das herunterfallende Bild [KRITERIEN HIER ERGÄNZEN], dann drücken Sie auf das Gutzeichen – anderenfalls auf das Kreuz. Für jeden Treffer gibts ein Smiley, jeder Fehler kostet ein Herzchen.',
    game_instructions_hangman: 'Tippen Sie auf die Buchstaben, von denen Sie glauben, dass sie im gesuchten Wort vorkommen. Für jeden Treffer gibts ein Smiley, jeder Fehlgriff kostet ein Herzchen.',
    game_resolve: 'Bei Game Over die korrekte Lösung anzeigen',
    // game_instructions_hint: 'Optional, wird vor Spielbeginn angezeigt.',
    games: 'Games',
    games_won: 'Games gewonnen',
    generate: 'Generieren',
    poi_disable: 'POI deaktivieren',
    poi_disabled: 'POI deaktiviert',
    poi_enable: 'POI aktivieren',
    poi_enabled: 'POI aktiviert',
    poi_invisible: 'POI unsichtbar machen',
    poi_open_on_visit: 'POI beim Betreten des Radius automatisch öffnen',
    delete_post: 'Posten löschen',
    post_disable: 'Posten deaktivieren',
    post_disabled: 'Posten deaktiviert',
    post_enable: 'Posten aktivieren',
    post_enabled: 'Posten aktiviert',
    post_invisible: 'Posten unsichtbar machen',
    qr_lock: 'Inhalt erst nach QR Code Scan anzeigen',
    qr_lock_text: 'Hinweis-Text bei QR Code Scanner',
    qr_lock_text_default: 'Falls Sie keinen Text angeben, wird folgender Satz angezeigt: "Suchen und scannen Sie den QR Code, um den Inhalt dieses Postens freizuschalten."',
    quantity: 'Anzahl',
    placeholder: 'Platzhalter-Text',
    profile_name: 'Titel der Profilseite',
    profile_map: 'Karte mit Startpunkten der Trails',
    profile_map_openstreetmap: 'OpenStreetMap Karte',
    profile_map_swisstopo_pixelkarte_farbe: 'SwissTopo Karte',
    profile_map_swisstopo_swissimage: 'SwissTopo Satellit',
    profile_public: 'Profilseite öffentlich zugänglich machen',
    profile_public_hint: 'Wenn Sie diesen Hacken setzen, kann Ihr Profil auf der smarTrails-Webseite gefunden werden.',
    profile_url: 'Externe URL für Profilseite',
    perms: 'Berechtigungen',
    perm_tour_add: 'Trails erstellen',
    perm_tour_delete: 'Trails löschen',
    perm_tour_publish: 'Trails publizieren',
    tour_perms: 'Zugriff auf Trails',
    confirm_title_change: 'Wenn Sie den Trail umbenennen, ändert sich auch die URL (Web-Adresse), unter welcher der Trail im Internet erreichbar ist.<br><br>Vergessen Sie nicht, externe Verlinkungen auf den Trails anzupassen sowie allfällige QR-Codes neu zu erstellen.',
    default_layer_posts: 'Auf Posten-Übersicht starten',
    delete_tour: 'Trail löschen',
    finish: 'Ziel',
    finishpage: 'Zielseite',
    finish_open_after_num_posts: 'Zielseite nach Besuch von Anzahl Posten öffnen',
    map_type: 'Map',
    map_type_openstreetmap: 'OpenStreetMap Karte',
    map_type_google_roadmap: 'Google Maps Karte',
    map_type_google_satellite: 'Google Maps Satellit',
    map_type_swisstopo_pixelkarte_farbe: 'SwissTopo Karte',
    map_type_swisstopo_swissimage: 'SwissTopo Satellit',
    payments: 'Bezahlfunktion',
    // payment_info: 'Kontaktieren Sie uns, wir unterstützen Sie gerne beim Einrichten eines Payrexx-Account',
    pay_instance: 'Payrexx Instanz',
    pay_secret: 'Payrexx API Secret',
    pay_amount: 'Betrag (CHF)',
    payrexx: 'Payrexx',
    payrexx_open: 'Zum Payrexx Konto',
    pois_before_posts: 'POIs vor Posten anzeigen',
    posts_open_unvisited: 'Öffnen von nicht besuchten Posten erlauben',
    posts_open_unvisited_visit: 'Manuell geöffnete Posten als besucht markieren', // TODO
    posts_order_show: 'Posten nummerieren',
    posts_order_force: 'Reihenfolge der Posten erzwingen',
    posts_show_next_only: 'Jeweils nur den nächsten Posten anzeigen',
    posts_show_next_only_hint: 'Wenn dieser Hacken gesetzt ist, wird beim Starten des Trails nur der erste Posten auf der Karte angezeigt. Die weiteren Posten erscheinen erst, nachdem der vorangehende Posten besucht wurde.',
    properties: 'Eigenschaften',
    publish_on: 'Verlinken auf',
    pub_pub: 'Publiziert',
    pub_chg: 'Nicht publizierte Änderungen',
    pub_not: 'Nicht publiziert',
    send_email: 'E-Mail senden',
    send_email_stats: 'Statistik-Daten einschliessen',
    signup: 'Registrieren',
    started: 'Gestartet',
    support: 'Support',
    height: 'Höhe',
    timer: 'Timer',
    timer_time: 'Zeit (Minuten)',
    timer_mode: 'Modus',
    timer_mode_countup: 'Zeitmessung (aufsteigend)',
    timer_mode_countdown: 'Countdown (absteigend)',
    tour_discover: 'discover.swiss',
    tour_embeddable: 'Externe Webseiten (eingebettet)',
    tour_duration: 'Dauer (Minuten)',
    tour_length: 'Strecke (Meter)',
    tour_open: 'Trail ist zurzeit geöffnet',
    tour_public: 'Profilseite',
    tour_public_hint: 'Wenn Sie diesen Hacken setzen, kann der Trail auf der smarTrails-Webseite gefunden und gestartet werden. Dies nach einem Review durch die Tourify GmbH.',
    tour_prevent_restart: 'Neustarten des Trails verhindern',
    tour_tags: 'Tags',
    tour_title: 'Name des Trails',
    type: 'Typ',
    about: 'About',
    add: 'Hinzufügen',
    agb: 'AGB',
    attributes: 'Attribute',
    buy: 'Kaufen',
    cancel: 'Abbrechen',
    collab: 'Collaboration',
    confirm: 'Sind Sie sicher?',
    confirm_del_content_and_sub: 'Inhalt und Unterinalte löschen?',
    content: 'Inhalt',
    copy: 'Kopieren',
    copy_noun: 'Kopie',
    copied: 'Kopiert',
    coord_east: 'Koordinaten Osten',
    coord_north: 'Koordinaten Norden',
    coord_south: 'Koordinaten Süden',
    coord_west: 'Koordinaten Westen',
    correspondence: 'Korrespondenz',
    delete: 'Löschen',
    delete_yes: 'Ja, löschen!',
    deleted: 'Gelöscht',
    description: 'Beschreibung',
    disabled: 'Deaktiviert',
    discard: 'Verwerfen',
    document: 'Dokument',
    edit: 'Bearbeiten',
    email: 'E-Mail',
    embed: 'Einbetten',
    embed_hint_singular: 'Fügen Sie diesen Code auf Ihrer Webseite ein, um den Trail einzubetten',
    embed_hint_plural: 'Fügen Sie diesen Code auf Ihrer Webseite ein, um Ihre Trails einzubetten',
    err_buy_no_recipient: 'Rechnungsempfänger fehlt!',
    err_no_valid_posts: 'Keine Posten, Inhalte oder Positionen vorhanden',
    err_poi_invalid_pos: 'POI ohne Position vorhanden',
    err_post_invalid_pos: 'Posten ohne Position vorhanden',
    err_slug_not_unique: 'Trail mit identischem Namen vorhanden',
    feedback: 'Feedback',
    feedback_show: 'Feedback-Button anzeigen',
    field_required: 'Muss ausgefüllt werden',
    file: 'Datei',
    file_too_big: 'Datei zu gross! Limite: {limit}MB',
    finished: 'Beendet',
    general: 'Allgemein',
    help: 'Hilfe',
    image: 'Bild',
    image_header: 'Bild für Header (Breitformat, ca. 8/1)',
    image_tile: 'Bild für Kachel (ca. 16/9)',
    import: 'Importieren',
    imprint: 'Impressum & Datenschutz',
    lang: 'Sprache',
    langs: 'Sprachen',
    logout: 'Abmelden',
    name: 'Name',
    new: 'Neu',
    no: 'Nein',
    map_overlay: 'Individuelle Karte',
    map_overlay_img: 'Bild (Kartenausschnitt)',
    map_overlay_north_west: 'Nord-Westen (oben links)', // TODO
    map_overlay_south_east: 'Süd-Osten (unten rechts)', // TODO
    map_route: 'Route',
    map_route_calculate: 'Route berechnen',
    map_route_import: 'Route importieren (GPX-Datei)',
    max_value: 'Maximum',
    min_value: 'Minimum',
    open_in_app: 'Vorschau',
    optional: 'optional',
    overview: 'Übersicht',
    poi: 'POI',
    pois: 'POI',
    position_missing: 'Position fehlt!',
    post: 'Posten',
    posts: 'Posten',
    preview: 'Vorschau',
    price: 'Preis',
    pricing: 'Pakete & Preise',
    provider: 'Anbieter',
    public: 'Öffentlich',
    publish: 'Publizieren',
    published: 'Publiziert',
    qrcode: 'QR Code',
    quiz: 'Quiz',
    radius: 'Radius',
    radius_hint: 'Empfohlen: 15m; Zwischen Gebäuden oder in Egnissen eher erhöhen',
    redeemed: 'Eingelöst',
    redeemed_by: 'Eingelöst durch',
    redeemed_at: 'Eingelöst am',
    register: 'Registrieren',
    register_finishing: 'Konto wird initialisiert, bitte warten..',
    save: 'Speichern',
    save_changes: 'Änderungen speichern?',
    save_publish: 'Speichern und Publizieren',
    selfservice: 'Self-Service Funktion zum Freischalten von Posten',
    services: 'Zusatzservices',
    settings: 'Einstellungen',
    share_show: 'Share-Button anzeigen',
    slug: 'Slug',
    state: 'Status',
    state_closed: 'geschlossen',
    state_open: 'geöffnet',
    stats: 'Statistik',
    store: 'Store',
    store_buy_tour: 'Trail kaufen',
    store_buy_tour_info: 'Bitte machen Sie folgende Angaben, um den Trail zu erwerben:',
    store_buy_tour_recipient: 'Rechnungsadresse',
    store_from: 'Aus Store',
    store_publish: 'Im Store publizieren',
    store_publish_tos_hint: 'Ich bestätige, dass ich den Trail im smarTrails Store zur Weiterverwendung und ggf. Weiterbearbeitung durch Dritte anbiete. Ich bestätige zudem, dass der Trail keine Inhalte (Texte, Bilder, Audio-Aufnahmen, Videos etc.) enthält, die durch ein Copyright Dritter geschützt sind.',
    store_amount: 'Verkaufspreis (CHF)',
    store_amount_free: '0.- (kostenlos)',
    store_amount_vat: 'MWST',
    store_amount_vat_excl: 'zzgl. MWST',
    store_amount_vat_incl: 'inkl. MWST',
    store_amount_vat_none: 'keine MWST',
    store_tour_imported_info: 'Trail erfolgreich importiert.\nBei Outdoor Trails müssen alle Posten und POIS neu positioniert werden.',
    store_tour_edit: 'Trail bearbeiten',
    store_resell: 'Weitervertrieb erlauben',
    subusers: 'Sub Users',
    // support: 'Support',
    // support_infos: 'Support Informationen',
    // support_infos_title: 'Ihre Kontaktangaben',
    support_contact: 'Kontakt für Support-Anfragen',
    tel: 'Telefon',
    title: 'Titel',
    title_quiz_hint: 'Optional. Beispiele: "Quiz", "Rätsel", ..',
    tour: 'Trail',
    tours: 'Trails',
    upgrade: 'Upgrade',
    upgrade_btn: 'Kontakt aufnehmen',
    upgrade_link: 'https://tourify.ch/kontakt',
    upgrade_text: 'Die Limite Ihrer aktuellen Lizenz ist erreicht. Gerne machen wir Ihnen ein Angebot für ein entsprechendes Upgrade.',
    upgrade_info: '<a href="https://tourify.ch/smartrails/pakete" target="_blank">Pakete & Preise</a>',
    upgrade_offer_btn: 'Offerte anfordern',
    upgrade_offer_link: 'https://tourify.ch/offerte-anfordern/',
    upsell_text: 'Diese Funktion ist in Ihrer aktuellen Lizenz nicht enthalten. Gerne machen wir Ihnen ein Angebot für ein entsprechendes Upgrade.',
    upsell_maps_title: 'Erweiterte Karten-Funktionen',
    upsell_maps_text: 'Mit dem «Maps Modul» lassen sich Ihre Trails noch individueller gestalten, indem Sie alternative Karten in Form von Grafikdateien einbinden. Zudem ermöglicht Ihnen das «Maps Modul» das Einzeichnen einer Route – automatisch, manuell oder durch Upload einer GPX-Datei.',
    upsell_payrexx_text: 'Bieten Sie Ihre Trails kostenpflichtig an. Dank der Schnittstelle zum Schweizer Zahlungsanbieter Payrexx lassen sich verschiedene Zahlungsmethoden einfach und bequem einbinden – beispielsweise Kreditkarten, Apple Pay oder TWINT.',
    upsell_timer_text: 'Das «Games Modul» bietet Ihnen die Möglichkeit, dem Trail eine Zeitlimite zu hinterlegen. Zudem stehen Ihnen verschiedene Online-Spiele zur Verfügung, die sich mit wenigen Klicks konfigurieren und in die Posten einfügen lassen.',
    upsell_vouchers_text: 'Generieren Sie Gutscheincodes zum Starten des Trails und verkaufen Sie diese direkt in Ihrem Online-Shop oder Tourist Office.',
    upsell_whitelabel_text: 'Gestalten Sie das Erscheinungsbild des Trails nach Ihrem Corporate Design. Mit «White Label» lassen sich die Farben und Schriftarten nach Ihren Vorstellungen anpassen, die Menü-Punkte individuell konfigurieren oder das smarTrails Logo durch Ihr eigenes ersetzen.\n\nPreis für alle Ihre Trails: 1200.- CHF/Jahr\n<small>Voraussetzung: Advanced Edition oder höher</small>',
    visited_posts: 'Besuchte Posten',
    voucher: 'Gutschein',
    voucher_add: 'Gutschein hinzufügen',
    voucher_code_exists: 'Code existiert bereits!',
    voucher_code_rule: 'Code muss alphanumerisch, gross geschrieben und 8 Zeichen lang sein.',
    voucher_note: 'Verwendungszweck (intern)',
    vouchers: 'Gutscheine',
    vouchers_generate: 'Gutscheine generieren',
    vouchers_show: 'Gutscheine anzeigen',
    whitelabel: 'White Label',
    word: 'Wort',
    word_rule: 'Nur Buchstaben (ohne Umlaute) erlaubt',
    yes: 'Ja',

    en: 'Englisch',
    de: 'Deutsch',
    fr: 'Französisch',
    it: 'Italienisch',
    rm: 'Rätoromanisch',
    es: 'Spanisch',
    ja: 'Japanisch',

    'tag_trail-loopTour': 'Rundtour',
    'tag_trail-scenic': 'aussichtsreich',
    'tag_general-suitableforfamilies': 'familienfreundlich',
    'tag_general-dogFriendly': 'hundefreundlich',
    'tag_accessibility-suitableforperambulators': 'kinderwagengerecht',
    'tag_transport-publicTransportFriendly': 'mit dem ÖV erreichbar',
    'tag_general-educational': 'lehrreich',
    'tag_incentive-quiz': 'mit Rätsel',
    'tag_incentive-reward': 'mit Belohnung am Ende',
    'tag_general-playground': 'Spielplatz unterwegs',
    'tag_general-fireplace': 'Feuerstelle unterwegs',
    'tag_general-dining': 'Einkehrmöglichkeit unterwegs',
    'tag_general-shopping': 'Einkaufsmöglichkeit unterwegs',
    'tag_general-publictoilet': 'öffentliche Toilette unterwegs',
    'tag_accessibility-wheelchair-accessible': 'rollstuhlgängig'
  },
  fr: {
    anonymous: 'Anonyme',
    loading: 'est chargé…',
    profile: 'Page de profil',
    unauthorized: 'PAS D\'ACCÈS',
    contact_support: 'Contacter le support',
    signed_in_as: 'Connecté en tant que',
    sign_out: 'Se déconnecter',
    qr_hint: 'ATTENTION : L\'URL dans le QR code contient le nom du trail. Si le trail est renommée, vous devrez générer un nouveau QR code. Les postes et les POI peuvent toutefois être renommés à tout moment sans que le QR code ne devienne invalide.',
    qr_hint_post: 'Ce code QR mène directement au poste lorsqu\'il est scanné.',
    correct: 'Correct',
    month: 'Mois',
    quizzes: 'Quiz',
    quizzes_correct: 'Quiz correctes',
    rating: 'Rating',
    rating_remarks: 'Commentaires',
    tour_not_public: 'Ce trail n\'as pas encore été publiée.',
    visitors: 'Visiteurs',
    week: 'Semaine',
    wrong: 'Faux',
    year: 'Année',
    total: 'Total',
    file_missing: 'Fichier manquant !',
    images: 'Images',
    cycle: 'Lecture automatique',
    continuous: 'Boucle infinie',
    interval: 'Intervalle (sec)',
    iframe_height: 'Hauteur (facultatif, à définir en pixels (par ex. 500px) ou en pourcentage (par ex. 80%))',
    iframe_height_custom: 'Définir la hauteur manuellement',
    icon: 'Icône',
    icon_auge: 'Oeil',
    icon_aussichtspunkt: 'Point de vue',
    icon_bahnhof: 'Gare ferroviaire',
    icon_bushaltestelle: 'Arrêt de bus',
    icon_einkaufsmoeglichkeit: 'Centre commercial',
    icon_feuerstelle: 'Cheminée',
    icon_haus: 'Maison',
    icon_kreuz: 'Croix (hôpital, médecin)',
    icon_parkplatz: 'Parking',
    icon_rastplatz: 'Aire de repos',
    icon_restaurant: 'Restaurant',
    icon_sehenswuerdigkeit: 'Attraction',
    icon_seilbahn: 'Téléphérique',
    icon_spielplatz: 'Terrain de jeux',
    icon_stern: 'Étoile',
    icon_touristinfo: 'Information touristique',
    icon_wc: 'WC',
    account: 'Compte d\'utilisateur',
    account_required: 'Connexion nécessaire',
    add_answer: 'Ajouter la réponse',
    answer: 'Réponse',
    answer_correct: 'Réponse correcte',
    answers: 'Réponses',
    answer_text_hint: 'Ajouter plusieurs réponses séparées par un point-virgule',
    before: 'avant le',
    choice: 'Choix',
    created: 'Crée',
    date_format: 'DD.MM.YYYY',
    expiry_date: 'Date d\'expiration',
    expiry_date_opt: 'Date d\'expiration (facultatif)',
    explain_correct: 'Explication après avoir répondu correctement',
    explain_wrong: 'Explication après une mauvaise réponse',
    explain_won: 'Explication après avoir gagné',
    explain_lost: 'Explication après avoir perdu',
    explainer_img: 'Image explicative (facultatif)',
    explainer_text: 'Explication',
    resolve: 'Afficher la solution correcte après avoir saisi la réponse',
    number: 'Numéro',
    mode: 'Mode',
    question: 'Question',
    text: 'Texte',
    tries: 'Nombre d\'essais',
    glb_missing: 'Fichier GLB manquant !',
    usdz_missing: 'Fichier USDZ manquant !',
    howto_text: 'Texte de mode d\'emploi',
    ar_howto: 'Si vous ne spécifiez aucun texte, la description suivante s\'affiche : Tapez sur le bouton rond et placez l\'objet sur une zone libre en bougeant votre appareil d\'avant en arrière. Vous pouvez alors vous promener autour de l\'objet, le regarder de tous les côtés, le déplacer (taper) ou modifier sa taille (geste de zoom).',
    glb_file: 'Fichier GLB',
    glb_file_hint: 'nécessaire',
    usdz_file: 'Fichier USDZ',
    usdz_file_hint: 'facultatif pour les objets animés',
    hr: 'Ligne',
    or: 'ou',
    vimeoid: 'ID vidéo Vimeo',
    ytvidid: 'ID vidéo YouTube',
    nocontent: 'Pas de contenu',
    nocontent_copy: 'Copier le contenu de la langue principale',
    nocontent_info: 'Pas encore de contenu - le contenu de la langue principale est utilisé.',
    nocontent_sub: 'Ajoutez des sous-contenus à l\'aide du bouton vert "plus" et glissez-les dans les éléments correspondants.',
    carousel: 'Carrousel',
    audio: 'Audio',
    video: 'Vidéo',
    expand: 'Élargir',
    height: 'Hauteur',
    timer: 'Timer',
    timer_time: 'Temps (minutes)',
    timer_mode: 'Modus',
    timer_mode_countup: 'Timer (croissant)',
    timer_mode_countdown: 'Countdown (décroissant)',
    html: 'HTML',
    smartvue: 'smartVue (AR)',
    iframe: 'iframe',
    additionallangs: 'Langues supplémentaires',
    delete_ask: 'Tous les contenus et sous-contenus seront supprimés ! Êtes-vous sûr ?',
    mainlang: 'Langue principale',
    image_choose: 'Choisir l\'image',
    or_dnd: 'ou glisser-déposer l\'image ici',
    remove: 'Enlever',
    never: 'jamais',
    no_data: 'Pas de données …',
    delete_poi: 'Supprimer le POI',
    form: 'Formulaire',
    forms: 'Formulaires',
    form_email_hint: 'Si vide, l\'adresse de correspondance (dans les paramètres) sera utilisé.',
    game: 'Jeu',
    game_imagepairs: 'Paires d\'images',
    game_imagepairs_img_hint: 'Les images doivent avoir un format carré.',
    game_falldown: 'Pluie d\'images',
    game_hangman: 'Deviner mot',
    game_won: 'Gagné',
    game_lost: 'Perdu',
    game_score: 'Nombre de "Corrects" pour gagner (Smileys)',
    game_lives: 'Nombre de "vies" par essai (cœurs)',
    game_idea: 'Soumettez votre idée pour un autre jeu',
    game_instructions: 'Instructions',
    game_instructions_show: 'Afficher les instructions de jeu',
    game_instructions_imagepairs: 'Quelles images vont ensemble ? Tapez sur l\'une des images listées ci-dessous, puis sur le champ correspondant.',
    game_instructions_falldown: 'Si l\'image qui tombe correspond à [REMPLACER LES CRITÈRES ICI], appuyez sur le signe "Bien" - sinon, appuyez sur la croix. Chaque réponse positive est accompagnée d\'un smiley, chaque erreur coûte un petit cœur.',
    game_instructions_hangman: 'Tapez sur les lettres qui, selon vous, se trouvent dans le mot recherché. Pour chaque réponse positive, vous recevez un smiley, pour chaque réponse négative, vous recevez un petit cœur.',
    game_resolve: 'Afficher la solution correcte en cas de Game Over',
    // game_instructions_hint: 'Facultatif, affiché avant le début du jeu.',
    games: 'Jeux',
    games_won: 'Jeux gagnés',
    generate: 'Générer',
    poi_disable: 'Désactiver le POI',
    poi_disabled: 'POI désactivé',
    poi_enable: 'Activer le POI',
    poi_enabled: 'POI activé',
    poi_invisible: 'Rendre le POI invisible',
    poi_open_on_visit: 'Ouvrir automatiquement le POI en entrant dans le rayon',
    delete_post: 'Supprimer le poste',
    post_disable: 'Désactiver le poste',
    post_disabled: 'Poste désactivé',
    post_enable: 'Activer le poste',
    post_enabled: 'Poste activé',
    post_invisible: 'Rendre le poste invisible',
    qr_lock: 'Afficher le contenu seulement après le scannage du QR code',
    qr_lock_text: 'Texte d\'indication pour le scanner de QR code',
    qr_lock_text_default: 'Si vous n\'indiquez pas de texte, la phrase suivante s\'affiche : "Cherchez et scannez le QR code pour débloquer le contenu de ce poste".',
    quantity: 'Quantité',
    placeholder: 'Description',
    profile_name: 'Nom / Titre de la page de profil',
    profile_map: 'Carte avec les points de départ des trails',
    profile_map_openstreetmap: 'Carte OpenStreetMap',
    profile_map_swisstopo_pixelkarte_farbe: 'Carte SwissTopo',
    profile_map_swisstopo_swissimage: 'Satellite SwissTopo',
    profile_public: 'Rendre la page de profil public',
    profile_public_hint: 'Si vous cochez cette case, votre page de profil peut être trouvé sur le site smarTrails.',
    profile_url: 'URL externe pour la page de profil',
    perms: 'Autorisations',
    perm_tour_add: 'Créer des trails',
    perm_tour_delete: 'Supprimer des trails',
    perm_tour_publish: 'Publier des trails',
    tour_perms: 'Accès aux trails',
    confirm_title_change: 'Si vous renommez le trail, l\'URL (adresse web) sous laquelle le trail est accessible sur Internet change aussi.<br><br>N\'oubliez pas d\'adapter les liens externes vers le trail et de recréer et remplacer les éventuels codes QR.',
    default_layer_posts: 'Démarrer sur l\'aperçu des postes',
    delete_tour: 'Supprimer le trail',
    finish: 'Fin',
    finishpage: 'Page Fin',
    finish_open_after_num_posts: 'Ouvrir la page fin après la visite du nombre de poste',
    map_type: 'Type de carte',
    map_type_openstreetmap: 'Carte OpenStreetMap',
    map_type_google_roadmap: 'Carte Google Maps',
    map_type_google_satellite: 'Satellite Google Maps',
    map_type_swisstopo_pixelkarte_farbe: 'Carte SwissTopo',
    map_type_swisstopo_swissimage: 'Satellite SwissTopo',
    payments: 'Paiements',
    // payment_info: 'Contactez-nous, nous vous aiderons volontiers à créer un compte Payrexx.',
    pay_instance: 'Instance Payrexx',
    pay_secret: 'Secret API Payrexx',
    pay_amount: 'Montant (CHF)',
    register: 'S\'inscrire',
    posts_open_unvisited: 'Permettre l\'ouverture des postes non visités',
    posts_order_show: 'Afficher l\'ordre des postes',
    posts_order_force: 'Forcer l\'ordre des postes',
    posts_show_next_only: 'Afficher uniquement le poste suivant à chaque fois',
    posts_show_next_only_hint: 'Si cette case est cochée, seuls le poste visité et le poste suivant sont affichés. Les visiteurs découvrent le parcours pas à pas.',
    properties: 'Propriétés',
    publish_on: 'Lien sur',
    pub_pub: 'Publié',
    pub_chg: 'Modifications non publiées',
    pub_not: 'Non publié',
    send_email: 'Envoyer E-Mail',
    send_email_stats: 'Inclure des données statistiques',
    signup: 'S\'enregistrer',
    started: 'Démarré',
    support: 'Support',
    tour_discover: 'discover.swiss',
    tour_embeddable: 'Pages web externes (intégrées)',
    tour_duration: 'Durée (minutes)',
    tour_length: 'Longueur (metres)',
    tour_open: 'Le trail est actuellement ouvert',
    tour_public: 'Page de profil',
    tour_public_hint: 'Si cette case est cochée, le trail peut être trouvé sur le site web de smarTrails et démarré après une révision par la Tourify GmbH.',
    tour_prevent_restart: 'Empêcher le redémarrage du trail',
    tour_tags: 'Tags',
    tour_title: 'Nom du trail',
    type: 'Type',
    about: 'À propos de',
    add: 'Ajouter',
    agb: 'Conditions générales',
    attributes: 'Attributs',
    buy: 'Acheter',
    cancel: 'Annuler',
    collab: 'Collaboration',
    confirm: 'Êtes-vous sûr ?',
    confirm_del_content_and_sub: 'Supprimer contenu et sous-contenus ?',
    content: 'Contenu',
    copy: 'Copier',
    copy_noun: 'Copie',
    copied: 'Copié',
    coords_east: 'Coordonnées est',
    coords_north: 'Coordonnées nord',
    coords_south: 'Coordonnées sud',
    coords_west: 'Coordonnées ouest',
    correspondence: 'Correspondance',
    delete: 'Supprimer',
    delete_yes: 'Oui, supprimer !',
    deleted: 'Supprimé',
    description: 'Description',
    disabled: 'Désactivé',
    discard: 'Rejeter',
    document: 'Document',
    edit: 'Éditer',
    email: 'E-Mail',
    embed: 'Intégrer',
    embed_hint_singular: 'Insérez ce code sur votre site web pour intégrer le trail',
    embed_hint_plural: 'Insérez ce code sur votre site web pour intégrer vos trails',
    err_buy_no_recipient: 'Le destinataire de la facture est manquant !',
    err_no_valid_posts: 'Aucun poste, aucun contenu ou aucune position trouvé',
    err_poi_invalid_pos: 'POI sans position trouvé',
    err_post_invalid_pos: 'Poste sans position trouvé',
    err_slug_not_unique: 'Trail existant avec un nom identique',
    feedback: 'Feedback',
    feedback_show: 'Afficher le bouton de feedback',
    field_required: 'Doit être rempli',
    file: 'Fichier',
    file_too_big: 'Fichier trop grand ! Limite : {limit}MB',
    finished: 'Terminée',
    general: 'Général',
    help: 'Aide',
    image: 'Image',
    image_header: 'Image pour l\'en-tête (format large, env. 8/1)',
    image_tile: 'Image pour carreau (env. 16/9)',
    import: 'Importer',
    imprint: 'Mentions légales & protection des données',
    lang: 'Langue',
    langs: 'Langues',
    logout: 'Se déconnecter',
    name: 'Nom',
    new: 'Nouveau',
    no: 'Non',
    map_overlay: 'Carte individuelle',
    map_route: 'Parcours',
    map_route_calculate: 'Calculer parcours',
    map_route_import: 'Importer un parcours (fichier GPX)',
    max_value: 'Maximum',
    min_value: 'Minimum',
    open_in_app: 'Prévisualisation',
    optional: 'facultatif',
    overview: 'Aperçu',
    poi: 'POI',
    pois: 'POI',
    position: 'Position',
    position_missing: 'Position manquante !',
    post: 'Poste',
    posts: 'Postes',
    preview: 'Prévisualisation',
    price: 'Prix',
    pricing: 'Paquets & Prix',
    provider: 'Fournisseur',
    public: 'Public',
    publish: 'Publier',
    published: 'Publié',
    qrcode: 'QR code',
    quiz: 'Quiz',
    radius: 'Rayon',
    radius_hint: 'Recommandé : 15m ; augmenter dans des environnements étroits, par ex. entre des bâtiments',
    redeemed: 'Encaissé',
    redeemed_by: 'Encaissé par',
    redeemed_at: 'Encaissé le',
    save: 'Sauvegarder',
    save_changes: 'Sauvegarder les modifications ?',
    save_publish: 'Sauvegarder et publier',
    selfservice: 'Fonction libre-service pour l\'activation des postes',
    services: 'Services supplémentaires',
    settings: 'Paramètres',
    share_show: 'Afficher le bouton de partage',
    slug: 'Slug',
    start: 'Début',
    state: 'Statut',
    state_closed: 'fermé',
    state_open: 'ouvert',
    stats: 'Statistiques',
    store: 'Magasin',
    store_buy_tour: 'Acheter trail',
    store_buy_tour_info: 'Veuillez fournir les informations suivantes pour acquérir le trail :',
    store_buy_tour_recipient: 'Adresse de facturation',
    store_from: 'De magasin',
    store_publish: 'Publier dans le magasin',
    store_publish_tos_hint: 'Je confirme que je propose le trail dans le smarTrails Store pour une réutilisation et éventuellement un traitement ultérieur par des tiers. Je confirme également que le trail ne contient aucun contenu (textes, images, enregistrements audio, vidéos, etc.) protégé par un copyright de tiers.',
    store_amount: 'Prix de vente (CHF)',
    store_amount_free: '0.- (gratuit)',
    store_amount_vat: 'TVA',
    store_amount_vat_excl: 'hors TVA',
    store_amount_vat_incl: 'TVA incluse',
    store_amount_vat_none: 'aucune TVA',
    store_tour_imported_info: 'Trail importé avec succès.\nPour les trails outdoor, tous les postes et POIs doivent être repositionnés.',
    store_tour_edit: 'Modifier le trail',
    store_resell: 'Autoriser la redistribution',
    subusers: 'Sous Utilisateurs',
    // support: 'Support',
    // support_infos: 'Informations de support',
    // support_infos_title: 'Vos coordonnées',
    support_contact: 'Contact pour les demandes d\'assistance',
    tel: 'Téléphone',
    title: 'Titre',
    title_quiz_hint: 'Facultatif. Exemples : "Quiz", "Énigme", …',
    tour: 'Trail',
    tours: 'Trails',
    upgrade: 'Mise à niveau',
    upgrade_btn: 'Prendre contact',
    upgrade_link: 'https://tourify.ch/fr/contact',
    upgrade_text: 'La limite de votre licence actuelle est atteinte. Veuillez nous contacter - nous vous ferons volontiers une offre pour une mise à niveau.',
    upgrade_info: '<a href="https://tourify.ch/smartrails/pakete" target="_blank">Paquets & Prix</a>',
    upgrade_offer_btn: 'Demander une offre',
    upgrade_offer_link: 'https://tourify.ch/offerte-anfordern/',
    upsell_text: 'Cette fonction n\'est pas incluse dans votre licence actuelle. Veuillez nous contacter - nous vous ferons volontiers une offre pour une mise à niveau.',
    upsell_maps_title: 'Fonctions cartographiques avancées',
    upsell_maps_text: 'Le «Module Maps» permet de personnaliser encore plus vos trails en intégrant des cartes alternatives sous forme de fichiers graphiques. De plus, ça vous permet de tracer un itinéraire - automatiquement, manuellement ou en téléchargeant un fichier GPX.',
    upsell_payrexx_text: 'Proposez vos trails contre paiement. Grâce à l\'interface avec le fournisseur de paiement suisse Payrexx, différentes méthodes de paiement peuvent être intégrées facilement et confortablement - par exemple les cartes de crédit, Apple Pay ou TWINT.',
    upsell_timer_text: 'Le «Module Jeux» vous offre la possibilité d\'associer une limite de temps au trail. De plus, vous disposez de différents jeux en ligne qui peuvent être configurés et insérés dans les postes en quelques clics.',
    upsell_vouchers_text: 'Générez des codes de bon pour démarrer le trail et vendez-les directement dans votre boutique en ligne ou votre office du tourisme.',
    upsell_whitelabel_text: 'Concevez l\'apparence du trail selon votre corporate design. Avec «White Label», vous pouvez adapter les couleurs et les polices de caractères à vos idées, configurer individuellement les points de menu ou remplacer le logo smarTrails par le vôtre.\n\nPrix pour tous vos trails : 1200.- CHF/an.\n<small>Condition: édition Advanced ou supérieure</small>',
    user: 'Utilisateur',
    visited_posts: 'Postes visités',
    voucher: 'Bon d’achat',
    voucher_add: 'Ajouter Bon d’achat',
    voucher_code_exists: 'Bon d’achat existe déjà!',
    voucher_code_rule: 'Le code doit être alphanumérique, en majuscules et comporter 8 caractères.',
    voucher_note: 'Note (interne)',
    vouchers: 'Bon d’achats',
    vouchers_generate: 'Générer Bon d’achats',
    vouchers_show: 'Afficher Bon d’achats',
    whitelabel: 'White Label',
    word: 'Mot',
    word_rule: 'Seules les lettres (sans tréma) sont autorisées',
    yes: 'Oui',

    en: 'Anglais',
    de: 'Allemand',
    fr: 'Français',
    it: 'Italien',
    rm: 'Romanche',
    es: 'Espagnol',
    ja: 'Japonais',

    'tag_trail-loopTour': 'Circuit',
    'tag_trail-scenic': 'scénique',
    'tag_general-suitableforfamilies': 'adapté aux familles',
    'tag_general-dogFriendly': 'chiens acceptés',
    'tag_accessibility-suitableforperambulators': 'accessibles aux poussettes',
    'tag_transport-publicTransportFriendly': 'accessible en transports publics',
    'tag_general-educational': 'instructif',
    'tag_incentive-quiz': 'avec énigme',
    'tag_incentive-reward': 'avec récompense à la fin',
    'tag_general-playground': 'Aire de jeux en chemin',
    'tag_general-fireplace': 'Foyer en chemin',
    'tag_general-dining': 'Possibilité de restauration en chemin',
    'tag_general-shopping': 'Options d\'achat disponibles',
    'tag_general-publictoilet': 'Toilettes publiques disponibles',
    'tag_accessibility-wheelchair-accessible': 'accessible en chaise roulante',

    payrexx: 'Payrexx',
    payrexx_open: 'Ouvrir compte Payrexx',
    pois_before_posts: 'Afficher les POIs avant les postes',
    register_finishing: 'Initialisation du compte, veuillez patienter…',
  },
}

<template>
  <v-app>

    <template v-if="$root.userId && $root.authorized">
      <v-navigation-drawer
        v-model="drawer"
        fixed
        app
      >
        <v-list-item class="pa-5">
          <v-img src="/img/logo.jpg" :aspect-ratio="800/147" width="100%"/>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense>
          <v-list-item v-if="!$root.subUser" to="/profile">
            <v-list-item-action>
              <v-icon>home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/tours">
            <v-list-item-action>
              <v-icon>list</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('tours') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!$root.subUser || !!$root.subUser.perms && $root.subUser.perms.includes('tour_add')" to="/store">
            <v-list-item-action>
              <v-icon>shop</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('store') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!$root.subUser" to="/live">
            <v-list-item-action>
              <v-icon>electric_bolt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('live') }}</v-list-item-title>
              <!-- <v-list-item-title class="d-flex justify-space-between">
                <span>{{ $t('live') }}</span>
                <v-chip color="secondary" x-small>{{ $t('new') }}</v-chip>
              </v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$root.perms.collab && !$root.subUser" to="/subusers">
            <v-list-item-action>
              <v-icon>people_outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('subusers') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>language</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="list-select">
              <v-select
                :items="localeItems"
                :value="$root.$i18n.locale"
                dense
                solo
                flat
                @change="$root.setLocale($event)"
              ></v-select>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
          <v-list-item href="https://tourify.ch/smartrails" target="_blank">
            <v-list-item-icon>
              <v-icon>info</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('about') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://tourify.ch/blog" target="_blank">
            <v-list-item-icon>
              <v-icon>feed</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tourify News</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://tourify.ch/support/" target="_blank">
            <v-list-item-icon>
              <v-icon>help</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('support') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://tourify.ch/agb/" target="_blank">
            <v-list-item-icon>
              <v-icon>class</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('agb') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="impressumLink" target="_blank">
            <v-list-item-icon>
              <v-icon>home_work</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('imprint') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
          <v-list-item href="https://tourify.ch/smartrails/pakete" target="_blank">
            <v-list-item-icon>
              <v-icon>inventory_2</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('pricing') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://tourify.ch/smartrails/pakete#zusatzservices" target="_blank">
            <v-list-item-icon>
              <v-icon>widgets</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('services') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="drawerRight"
        fixed
        app
        right
        clipped
        :mobile-breakpoint="1024"
        width="360"
        class="help-drawer"
      >
        <v-container style="height: 100%">
          <v-card style="height: 100%">
            <v-toolbar dense flat>
              <v-toolbar-title>{{ $t('help') }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="drawerRight = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text ref="helpContents" style="height: calc(100% - 48px); overflow-y: auto;">
              <content-root :contents="$root.helpContents"/>
              <div class="mt-10 mb-5 text-center">
                <v-btn href="https://tourify.ch/support/" target="_blank">
                  <v-icon left>call</v-icon> {{ $t('contact_support')}}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-container>
      </v-navigation-drawer>

      <v-app-bar app extended clipped-right color="primary" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>smarTrails<sup>&reg;</sup></v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-btn v-if="showBack" @click="$root.goBack" icon>
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title | capitalize }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="drawerRight = !drawerRight">
            <v-icon>help_outline</v-icon>
          </v-btn>
        </template>
        <v-menu v-model="accountMenu" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>account_circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ userName }}</v-list-item-title>
                <v-list-item-subtitle>{{ $root.user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="ma-1" v-if="accountOpts">
              <v-divider class="mb-1"></v-divider>
              <v-subheader class="px-3" style="height: 32px">Account</v-subheader>
              <v-select
                :value="$root.accountId"
                :items="accountOpts"
                dense
                hide-details
                solo
                flat
                class="mb-3"
                @change="$root.selectAccount"
              />
            </div>
            <v-divider class="mb-3"></v-divider>
            <v-list-item dense @click="$root.signOut">
              <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-snackbar v-model="showSnack" :color="$root.snack && $root.snack.color ? $root.snack.color : 'success'" top>
        {{ $root.snack.text || $root.snack }}
      </v-snackbar>

      <v-main style="height: 100vh;">
        <v-container fluid style="height: 100%"> <!-- fluid = fill-width -->
          <router-view style="height: 100%;"/>
        </v-container>
      </v-main>

      <v-footer color="primary" app class="footer">
        <a href="https://tourify.ch/" target="_blank" class="px-2 white--text" style="text-decoration: none">&copy; Tourify GmbH</a>
      </v-footer>

      <v-dialog v-model="$root.upgradeDlg" width="500">
        <v-card class="dlg">
          <v-toolbar dark color="primary">
            <v-btn icon disabled></v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $t('upgrade') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$root.upgradeDlg = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="text-center">
            <div class="my-5">{{ $t('upgrade_text') }}</div>
            <v-btn dark color="secondary" :href="$t('upgrade_link')" target="_blank">{{ $t('upgrade_btn') }}</v-btn>
            <div class="mt-5" v-html="$t('upgrade_info')"></div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="$root.upsellDlg" width="500">
        <v-card class="dlg">
          <v-toolbar dark color="primary">
            <v-btn icon disabled></v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $t('upgrade') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$root.upsellDlg = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="text-center">
            <div class="multiline text-left my-5">{{ $t('upsell_text') }}</div>
            <v-btn dark color="secondary" :href="$t('upgrade_offer_link') + '?' + $root.upsellType" target="_blank">{{ $t('upgrade_offer_btn') }}</v-btn>
            <div class="mt-5" v-html="$t('upgrade_info')"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <template v-else-if="$root.userId === null && $root.authorized === false">
      <login-form></login-form>
    </template>

    <template v-else-if="$root.userId !== null && $root.authorized === false && $root.user.slug === undefined">
      <div class="center-center">{{ $t('register_finishing') }}</div>
    </template>

    <template v-else-if="$root.userId !== null && $root.authorized === false && $root.user.slug !== undefined">
      <div class="center-center">
        <div class="my-7">{{ $t('unauthorized') }}</div>
        <div class="my-7">
          <div>{{ $t('signed_in_as') }}:</div>
          <div>{{ $root.user.displayName || $root.user.email }}</div>
          <div class="my-3"><v-btn dark color="secondary" @click="$root.signOut">{{ $t('sign_out') }}</v-btn></div>
        </div>
        <div class="my-7"><a href="https://tourify.ch/support/" target="_blank">{{ $t('contact_support') }}</a></div>
      </div>
    </template>

    <template v-else>
      <div class="center-center">{{ $t('loading') }}</div>
    </template>

  </v-app>
</template>

<script>
import ContentRoot from '@/components/contentview/ContentRoot'
import LoginForm from '@/components/LoginForm'

export default {
  name: 'App',
  components: {
    ContentRoot,
    LoginForm
  },
  data () {
    return {
      drawer: null,
      drawerRight: null,
      accountMenu: false,
      showSnack: false
    }
  },
  computed: {
    title () {
      return this.$t(this.$route.name)
    },
    showBack () {
      return this.$route.path.split('/').length > 2
    },
    localeItems () {
      return this.$root.$i18n.locales.map(l => ({
        text: l.toUpperCase(),
        value: l
      }))
    },
    userName () {
      return this.$root.user.author_name || this.$root.user.name__de || this.$root.user.name__en
    },
    accountOpts () {
      const accountOpts = []
      if (this.$root.userPerms?.backend) {
        accountOpts.push({ text: this.userName, value: this.$root.userId })
      }
      this.$root.subusers?.forEach(s => {
        accountOpts.push({ text: s.accountName, value: s.accountId })
      })
      return accountOpts.length > 1 ? accountOpts : null
    },
    impressumLink () {
      return 'https://tourify.ch/impressum/' + (this.$root.$i18n.locale === 'en' ? 'en/' : '')
    }
  },
  watch: {
    '$root.snack': function (val) {
      if (val) this.showSnack = true
    },
    'showSnack': function (val) {
      if (!val) this.$root.snack = ''
    },
    '$root.helpContents': function () {
      if (this.$refs.helpContents) {
        this.$refs.helpContents.scrollTo(0, 0)
      }
    }
  },
  filters: {
    capitalize (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>

<style scoped>
.list-select {
  padding: 0 !important;
}
.list-select >>> .v-label {
  font-size: 13px;
  font-weight: 500;
  color: inherit;
}
.list-select >>> .v-select {
  font-size: 13px;
  font-weight: 500;
}
.list-select >>> .v-input__slot {
  padding: 0 20px 0 0 !important;
  margin: 0 !important;
}
.list-select >>> .v-text-field__details {
  padding: 0 20px 0 0 !important;
  margin: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
}
.center-center {
  position: fixed;
  top: 40%;
  width: 100%;
  text-align: center;
}
.help-drawer {
  background-color: inherit !important;
}
.help-drawer >>> .container {
  padding-left: 3px;
}
.help-drawer >>> .v-navigation-drawer__border {
  display: none;
}
</style>

<style>
.theme--light.v-toolbar {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.v-card__title {
  word-break: normal !important;
}
.v-input--selection-controls {
  margin-top: 0;
  margin-bottom: 16px;
}
.card-flex-title {
  display: flex;
  justify-content: space-between;
}
.tlb >>> .v-toolbar__content {
  padding: 0 24px 0 16px;
}
.tbs {
  border-bottom: 1px solid #f5f5f5
}
.tbs >>> .v-tabs__item {
  padding: 6px 16px;
}
.footer {
  z-index: 5 !important;
}
.bg-striped {
  background: repeating-linear-gradient(-45deg, transparent, transparent 10px, #f5f5f5 10px, #f5f5f5 20px);
}
hr {
  border-style: solid;
  color: lightgrey;
}
.multiline {
  white-space: pre-line;
}
.leaflet-container {
  z-index: 0;
}
</style>

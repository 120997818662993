import { render, staticRenderFns } from "./ContentAudio.vue?vue&type=template&id=bce8d8bc&scoped=true"
import script from "./ContentAudio.vue?vue&type=script&lang=js"
export * from "./ContentAudio.vue?vue&type=script&lang=js"
import style0 from "./ContentAudio.vue?vue&type=style&index=0&id=bce8d8bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.3_webpack@5.80.0__lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.80.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bce8d8bc",
  null
  
)

export default component.exports